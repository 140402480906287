import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import AnimPageContainer from "../components/layout/animPageCont"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { upDown } from "../animations"
import ContentBlock from "../components/contentBlock"
import {H1, Hr} from "../components/layout/Styles"
// Animated GIFs
import optotypes from "../images/features/optotypes.gif"
import randomise from "../images/features/randomise.gif"
import mask from "../images/features/mask.gif"
import revealLine from "../images/features/reveal-line.gif"
import rgMask from "../images/features/rgMask.gif"
import nrOfLetters from "../images/features/nrOfLetters.gif"
import sizeNotation from "../images/features/sizeNotation.gif"
import jcc from "../images/features/jcc.gif"
import clocks from "../images/features/clocks.gif"
import fanBlock from "../images/features/fanBlock.gif"
import crossCyl from "../images/features/crossCyl.gif"
import duochrome from "../images/features/duochrome.gif"
import w4d from "../images/features/w4d.gif"
import phoria from "../images/features/phoria.gif"
import schober from "../images/features/schober.gif"
import cs from "../images/features/cs.gif"
import cv from "../images/features/cv.gif"
import stereo from "../images/features/stereo.gif"
import images from "../images/features/images.gif"
import sequences from "../images/features/sequences.gif"
import bgContrast from "../images/features/bgContrast.gif"
import { LinkBtn } from "../components/layout/ui"

const blockData = [
  {
    imacContent: optotypes,
    heading: <h3>Visual Acuity Charts with 5 different Optotypes</h3>,
    text: (
      <p>
        Easily switch between optotypes by pressing Shift-Left, Shift-Right or
        the shortcut key for each of the optotypes
      </p>
    ),
    list: [
      <li>Sloan Letters (L)</li>,
      <li>Tumbling E (E)</li>,
      <li>Landolt Rings (C)</li>,
      <li>Lea Symbols (P)</li>,
      <li>Numbers</li>,
    ],
  },
  {
    imacContent: randomise,
    heading: <h3>One Click Letter Randomisation</h3>,
    text: <p>Any of the following actions randomises the letters</p>,
    list: [
      <li>Shortcut key (R) on the keyboard</li>,
      <li>Clicking the scroll wheel on your mouse</li>,
      <li>Pressing the Randomise button on the remote</li>,
    ],
  },
  {
    imacContent: mask,
    heading: <h3>Horizontal, Vertical &amp; Single Letter Mask</h3>,
    text: <p>Shortcut keys for various mask</p>,
    list: [
      <li>Horizontal Line (H)</li>,
      <li>Vertical Line (U)</li>,
      <li>Single Letter (S)</li>,
      <li>Full Chart (F)</li>,
    ],
  },
  {
    imacContent: revealLine,
    heading: <h3>Reveal Line</h3>,
    text: (
      <p>
        The currently selected or active line can be revealed in full screen by
        pressing (Enter). This is a useful little feature in the case that the
        patient's VA is better than the examiner'
      </p>
    ),
  },
  {
    imacContent: rgMask,
    heading: <h3>Duochrome Mask</h3>,
    text: (
      <p>
        A Red-Green mask can be applied over any active chart or target. The
        shortcut key is (G)
      </p>
    ),
  },
  {
    imacContent: nrOfLetters,
    heading: <h3>Change the number of letters displayed</h3>,
    text: (
      <p>
        Easily change the number of letters displayed in each row. Shortcut Keys
      </p>
    ),
    list: [
      <li>5 Letters in Line (Shift + 5)</li>,
      <li>4 Letters in Line (Shift + 4)</li>,
      <li>3 Letters in Line (Shift + 3)</li>,
      <li>2 Letters in Line (Shift + 2)</li>,
      <li>1 Letters in Line (Shift + 1)</li>,
      <li>Increase nr of Letters &gt;</li>,
      <li>Decrease nr of Letters &lt;</li>,
    ],
  },
  {
    imacContent: sizeNotation,
    heading: <h3>Change line Size notation</h3>,
    text: (
      <p>
        Easily change the line size notation between Metric and USC simply by
        clicking on the line size display. The dafault can be set in the
        settings menu
      </p>
    ),
  },
  {
    imacContent: jcc,
    heading: <h3>Resizable Jackson Cross Cyl Target</h3>,
    text: (
      <p>
        Jacson Cross Cyl Target for Determining the astigmatism axis. Resize
        with mouse scroll wheel or with (Up Arrow) and (Down Arrow) shortcut
        keys
      </p>
    ),
  },
  {
    imacContent: clocks,
    heading: <h3>Resizable Clock Dials</h3>,
    text: (
      <p>
        Two separate resizable clock dials for determining the astigmatism axis.
        Resize with mouse scroll wheel or with (Up Arrow) and (Down Arrow)
        shortcut keys
      </p>
    ),
  },
  {
    imacContent: fanBlock,
    heading: <h3>Interactive Fan &amp; Block Test</h3>,
    text: (
      <p>
        The Fan &amp; Block Test is to determine astigmatism axis &amp; power
      </p>
    ),
    list: [
      <li>
        The green pointer is moved with the arrows to the clearest line on the
        Fan
      </li>,
      <li>Pressing (Enter) advances to the V Pointer</li>,
      <li>
        V Pointer is rotated with arrow buttons until the two legs are equally
        clear
      </li>,
      <li>Pressing (Enter) advances to the Blocks</li>,
      <li>
        Cyl power is adjusted until the lines on the left &amp; right blocks are
        equally clear
      </li>,
    ],
  },
  {
    imacContent: crossCyl,
    heading: <h3>Rotatable &amp; Resizable Cross Cyl Target</h3>,
    text: (
      <p>
        The Cross Cyl Target can be used to refine the endpoint of the distance
        refraction. It can also be used to refine the cylinder power
      </p>
    ),
    list: [
      <li>
        Increase &amp; Decrease Size with (Up Arrow) &amp; (Down Arrow) shortcut
        keys
      </li>,
      <li>
        Rotate the axis with (Left Arrow) &amp; (Right Arrow) shortcut keys
      </li>,
    ],
  },
  {
    imacContent: duochrome,
    heading: <h3>Douchrome Balance</h3>,
    text: (
      <p>
        Various resizable and symmetrical duochrome balance targets. Shortcuts
      </p>
    ),
    list: [
      <li>Switch to Duochrome Balance (D)</li>,
      <li>Resize target (Up Arrow) &amp; (Down Arrow)</li>,
      <li>Switch targets (Shift + Left) &amp; (Shift + Right)</li>,
    ],
  },
  {
    imacContent: w4d,
    heading: <h3>Worth 4 Dot</h3>,
    text: (
      <p>
        Worth 4 Dot Target which can be resized. Like with all the anaglyph
        based tests, you switch between a black or white background
      </p>
    ),
    list: [
      <li>Switch to Binocular Vision Tests (B)</li>,
      <li>Resize target (Up Arrow) &amp; (Down Arrow)</li>,
      <li>Toggle white or black background (W)</li>,
    ],
  },
  {
    imacContent: phoria,
    heading: <h3>Associated Phorias &amp; Fixation Disparity</h3>,
    text: (
      <p>
        Test vertical &amp; horizontal phorias with or without a fixation dot.
        Align the lines with the arrow keys in order to measure fixation
        disparity
      </p>
    ),
    list: [
      <li>
        Press (B) repeatedly to switch between horizontal, vertical and other
        binocular vision targets
      </li>,
      <li>Resize target (Up Arrow) &amp; (Down Arrow)</li>,
      <li>
        Align red &amp; green lines with (Left Arrow) &amp; (Right Arrow)
      </li>,
    ],
  },
  {
    imacContent: schober,
    heading: <h3>Schober Ring</h3>,
    text: (
      <p>
        Like all the anaglyph based tests, the Schober Ring can also be done on
        a white or black backgroun
      </p>
    ),
    list: [
      <li>Press (B) repeatedly to switch between binocular vision targets</li>,
      <li>Resize target (Up Arrow) &amp; (Down Arrow)</li>,
    ],
  },
  {
    imacContent: cs,
    heading: <h3>Contrast Sensitivity Screening</h3>,
    text: (
      <p>
        Check the relative contrast sensitivity expressed as a percentage. You
        can display the chart in any of the 5 optotypes and the letters can be
        randomised
      </p>
    ),
    list: [
      <li>Press (K) to switch to Contrast Sensitivity Charts</li>,
      <li>Press (Shift + Right) to go to next optotype</li>,
      <li>Press (Shift + Left) to go to previous optotype</li>,
      <li>
        Select &amp; highlight next or previous line (Up Arrow) &amp; (Down
        Arrow)
      </li>,
      <li>Reveal highlighted line (Enter)</li>,
      <li>Randomise letters (R)</li>,
    ],
  },
  {
    imacContent: cv,
    heading: <h3>Colour Vision Screening</h3>,
    text: <p>Colour Vision screening with 11 Ishihara plate</p>,
    list: [
      <li>Press (I) to switch to Colour Vision test</li>,
      <li>Press (I) repeatedly to cycle through plates</li>,
      <li>Press (Shift + Right) to go to next plate</li>,
      <li>Press (Shift + Left) to go to previous plate</li>,
      <li>Resize plates with (Up Arrow) &amp; (Down Arrow)</li>,
    ],
  },
  {
    imacContent: stereo,
    heading: <h3>Random Dot Stereopsis Test</h3>,
    text: (
      <p>
        Test Stereopsis from 400" to 20" of arc. Randomly selects one of the
        four dots to displace each time. This test is performed with anaglyphs
      </p>
    ),
    list: [
      <li>Press (X) to switch to Stereopsis Test</li>,
      <li>Press (Down Arrow) to reduce the angle of deviation</li>,
      <li>Press (Up Arrow) to increse the angle of deviation</li>,
      <li>Press (Enter) to reveal which of the dots are displaced</li>,
    ],
  },
  {
    imacContent: images,
    heading: <h3>Image Slideshows for Fixation</h3>,
    text: (
      <p>
        Image slide shows from various categories for patients to fixate on
        while doing retinoscopy. This is especially useful for children to keep
        their attention on the screen
      </p>
    ),
    list: [
      <li>Press (T) to switch to Fixation Images</li>,
      <li>
        Press (Shift + Right) &amp; (Shift + Left) to change the category of
        images
      </li>,
    ],
  },
  {
    imacContent: sequences,
    heading: <h3>Program a custom sequence </h3>,
    text: (
      <p>You can create and save your own sequences of charts to display.</p>
    ),
    list: [
      <li>Press (Space) to go to next chart in sequence.</li>,
      <li>Press (Shift + Space) to go to previous chart in sequence.</li>,
    ],
  },
  {
    imacContent: bgContrast,
    heading: <h3>Adjust the background contrast</h3>,
    text: (
      <p>
        You can aadjust the contrast between the background and the letters.
      </p>
    ),
    list: [
      <li>Press (PageUp) to increase the background contrast</li>,
      <li>Press (PageDown) to decrease the background contrast</li>,
    ],
  },
  {
    buttons: [
      <LinkBtn to="/how">How to Use Eyechart.Online </LinkBtn>,
      // <ExtLinkBtn
      //   href="https://eyechart.online"
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   Start Application
      // </ExtLinkBtn>,
    ],
  }
]

const ScrollHomeBtn = styled.button.attrs({
  type: "button",
})`
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  display: block;
  color: salmon;
  padding: 0px;
  animation: ${upDown} 1s ease-in-out infinite both;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 4em;
  /* text-shadow: 1px 1px 2px rgba(0,0,0,0.75); */
  cursor: pointer;
`

const ThirdPage = () => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.1,
  })
  // const homeRef = useRef(null)

  return (
    <>
      <SEO title="Page Three" />
      <Layout>
        <H1 ref={ref}>
          Complete List of Features
        </H1>
        <Hr style={{ backgroundColor: "salmon" }} />
        {blockData.map((obj, idx) => (
          <React.Fragment key={`fr-${idx}`}>
            <ContentBlock key={`cb-${idx}`} childKey={`cb-${idx}`} threshold={0.2} {...obj} />
            {idx < blockData.length - 1 && <Hr />}
          </React.Fragment>
        ))}
        {!inView && (
          <ScrollHomeBtn
            onClick={() =>
              document
                .querySelector("#start")
                .scrollIntoView({ block: "start", behavior: "smooth" })
            }
          >
            ⬆
          </ScrollHomeBtn>
        )}
      </Layout>
    </>
  )
}

export default ThirdPage
